import { Component, computed, Signal } from '@angular/core';
import { ApiKeysService } from '../api-keys/api-keys.service';
import { UserGroup } from '../../../nucleus/v2/groups-http.v2.service';
import { first, map, pluck, shareReplay, switchMap, tap } from 'rxjs/operators';
import { UserGroupsFacade } from '../user-groups/user-groups.facade';
import { NgForm, ValidationErrors, FormsModule } from '@angular/forms';
import { UsersService } from '../users/users.service';
import {
  IS_ASSOCIATE_ORG,
  IS_FREE_ORG,
  IS_PREMIUM_ORG,
} from '../../shared/access-check/access-check-condition.model';
import User from '../user-editor/user.model';
import { AppState } from '../core.store';
import { select, Store } from '@ngrx/store';
import { selectUserID } from '../auth/auth.selectors';
import { UserRole } from 'src/nucleus/v2/models/user-roles.model';
import { FeatureSwitchService } from '../../features/feature-switch/feature-switch.service';
import { SettingsBreadcrumbComponent } from '../../shared/breadcrumb/settings-breadcrumb.component';
import { AsyncPipe } from '@angular/common';
import { NgFormControlValidatorDirective } from '../../shared/form-helpers/ng-form-control-validator.directive';
import { SpinnerButtonComponent } from '../../shared/spinner-button/spinner-button.component';
import { ShowIfDirective } from '../../shared/access-check/directives/show/show-if.directive';
import { RouterLink } from '@angular/router';
import { ApiKeysTableComponent } from '../api-keys/api-keys-table/api-keys-table.component';
import { MfaKeysTableComponent } from '../mfa/mfa-keys-table/mfa-keys-table.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { OrganizationManagementService } from '@geneious/nucleus-api-client';
import { isBXOrg } from '../utils/organization-utils';
import { ComponentDisplayService } from '../../features/feature-switch/component-display.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'bx-user-account-page',
  templateUrl: './user-account-page.component.html',
  standalone: true,
  imports: [
    SettingsBreadcrumbComponent,
    FormsModule,
    NgFormControlValidatorDirective,
    SpinnerButtonComponent,
    ShowIfDirective,
    RouterLink,
    ApiKeysTableComponent,
    MfaKeysTableComponent,
    AsyncPipe,
  ],
})
export class UserAccountPageComponent {
  readonly isApiKeysEnabled = toSignal(this.apiKeysService.isApiKeysEnabled());
  readonly isMfaEnabled = toSignal(this.featureSwitchService.isEnabledOnce('mfaCreationEnabled'));
  readonly isEmailChangingEnabled = toSignal(
    this.featureSwitchService.isEnabledOnce('changeEmail'),
  );
  readonly user: Signal<User>;
  readonly groups: Signal<UserGroup[]>;
  readonly isBXOrg: Signal<boolean>;

  showMFA$: Observable<boolean>;

  readonly isAssociateOrg = IS_ASSOCIATE_ORG;
  readonly isFreeOrg = IS_FREE_ORG;
  readonly isPremiumOrg = IS_PREMIUM_ORG;

  processingRequest = false;
  errors: string[] = [];
  areChangesSaved = false;

  constructor(
    private store: Store<AppState>,
    private apiKeysService: ApiKeysService,
    private usersService: UsersService,
    private userGroupsFacade: UserGroupsFacade,
    private organizationManagementService: OrganizationManagementService,
    private featureSwitchService: FeatureSwitchService,
    private componentDisplayService: ComponentDisplayService,
  ) {
    const user$ = this.store.pipe(
      select(selectUserID),
      first(),
      switchMap((userID) => this.usersService.getUser(userID)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    const groups$ = user$.pipe(switchMap((user) => this.userGroupsFacade.getGroups(user.id)));
    const organization$ = user$.pipe(
      switchMap((user) => this.organizationManagementService.getOrganization(user.organizationId)),
      pluck('data'),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.showMFA$ = componentDisplayService
      .get()
      .isGeneiousView()
      .pipe(
        map((value) => {
          return !value;
        }),
      );

    this.user = toSignal(user$);
    this.groups = toSignal(groups$, { initialValue: [] });
    const organization = toSignal(organization$);

    this.isBXOrg = computed(() => {
      const org = organization();

      if (!org) {
        return false;
      }

      return isBXOrg(org);
    });
  }

  submit(usersForm: NgForm) {
    // todo clear the form errors somewhere
    const formErrors: ValidationErrors = usersForm.errors ?? [];
    this.errors = Object.keys(formErrors).map((key) => formErrors[key]);
    if (usersForm.valid) {
      this.processingRequest = true;
      this.errors = [];
      this.usersService.editUser(this.user()).subscribe({
        next: () => {
          this.processingRequest = false;
          this.showChangesSavedAlert();
        },
        error: () => {
          this.errors.push('Failed to save changes to user');
          this.processingRequest = false;
        },
      });
    }
  }

  showChangesSavedAlert() {
    this.areChangesSaved = true;
    setTimeout(() => {
      this.areChangesSaved = false;
    }, 3000);
  }

  formatRole(userRole: UserRole): string {
    return User.calculateRole([userRole]);
  }
}
