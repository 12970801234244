import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NavigationService } from '../../core/navigation/navigation.service';
import { AccessCondition } from '../../shared/access-check/access-check-condition.model';
import { AccessCheckService } from '../../shared/access-check/access-check.service';
import { ComponentDisplayService } from '../../features/feature-switch/component-display.service';

/**
 * Protects Biologics-specific features from non-Biologics users.
 *
 * 'biologicsComponentsView' - based on biologicsComponentsView feature switch, relates to Biologics only pages.
 * 'folderFileView' - based on fileFolderView feature switch, relates to folder pages, which may be on/off in non-Biologics orgs.
 * 'hasPipelineAssociations' - based on org's added pipeline associations, relates to jobs, uploads, and jobs columns in user table.
 *
 */
@Injectable({
  providedIn: 'root',
})
export class DisplayComponentCheckGuard {
  constructor(
    private readonly navigationService: NavigationService,
    private readonly componentDisplayService: ComponentDisplayService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<true | UrlTree> {
    const displayType: string = route.data?.displayType;

    switch (displayType) {
      case 'biologicsComponentsView':
        return this.componentDisplayService
          .get()
          .showExtraComponents()
          .pipe(
            switchMap((hasAccess): Observable<true | UrlTree> => {
              return hasAccess ? of(hasAccess) : this.navigationService.getHomeUrlTree();
            }),
          );
      case 'hasPipelineAssociations': {
        return this.componentDisplayService
          .get()
          .hasPipelineAssociations()
          .pipe(
            switchMap((hasAccess): Observable<true | UrlTree> => {
              return hasAccess ? of(hasAccess) : this.navigationService.getHomeUrlTree();
            }),
          );
      }
      case 'folderFileView': {
        return this.componentDisplayService
          .get()
          .showFoldersFiles()
          .pipe(
            switchMap((hasAccess) => {
              return hasAccess ? of(hasAccess) : this.navigationService.getHomeUrlTree();
            }),
          );
      }
      default:
        return of(true);
    }
  }
}
