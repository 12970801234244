import { Injectable } from '@angular/core';
import { ComponentDisplaySwitches } from './component-display-switches.service';
import { FeatureSwitchService } from './feature-switch.service';
import { PipelineAssociationsService } from '../../core/pipeline/pipeline-associations/pipeline-associations.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

/**
 * This Service handles switches related to which components are displayed.
 *
 * folderFileView - for folders and files in Shared Workspace, Org Database, etc.
 * defaultGeneiousDisplay - for links & text related to Geneious, not Geneious Biologics
 * Pipeline Associations - for jobs columns in Users table
 *
 * Example Use:
 *  componentDisplayService.get().showFoldersFiles()
 *  to get Observable<boolean> for current org folderFileView feature switch
 */
export class ComponentDisplayService {
  private currentOrgSwitchObject: ComponentDisplaySwitches;

  constructor(
    private featureSwitchService: FeatureSwitchService,
    private pipelineAssociationsService: PipelineAssociationsService,
  ) {
    const folderFileView = this.featureSwitchService.isEnabled('folderFileView'); // if true, folders & files shown
    const geneiousView: Observable<boolean> =
      this.featureSwitchService.isEnabledOnce('defaultGeneiousDisplay');
    const biologicsComponentsView: Observable<boolean> =
      this.featureSwitchService.isEnabledOnce('biologicsComponentsView');
    const pipelines = this.pipelineAssociationsService.getProfilePipelineAssociations();
    this.currentOrgSwitchObject = ComponentDisplaySwitches.getInstance(
      folderFileView,
      pipelines,
      geneiousView,
      biologicsComponentsView,
    );
  }

  public get(): ComponentDisplaySwitches {
    return this.currentOrgSwitchObject;
  }
}
