import { ChangeDetectionStrategy, Component, HostBinding, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../../../app.config';
import { ExternalWrapperComponent } from '../external-wrapper/external-wrapper.component';

import { FreeTierLoginComponent } from '../signup/free-tier-login/free-tier-login.component';
import { LoginFormComponent } from '../signup/login-form/login-form.component';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'bx-login-or-signup-wrapper',
  templateUrl: './login-or-signup-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ExternalWrapperComponent, FreeTierLoginComponent, LoginFormComponent, LoginComponent],
})
export class LoginOrSignupWrapperComponent {
  @HostBinding('class') readonly hostClass = 'd-block';
  readonly isFreeTierLogInEnabled: boolean;

  constructor(@Inject(APP_CONFIG) private config: AppConfig) {
    this.isFreeTierLogInEnabled = config.freeTierLoginEnabled;
    if (this.config.useAutomaticDAAuth === true) {
      this.navigateToDirectAuthentication();
    }
  }

  navigateToDirectAuthentication() {
    // navigate to google for now!!
    console.log('`${this.config.nucleusApiBaseUrl}/api/nucleus/v2/auth/oidc/GoogleOidcClient`');
    document.location.href = `${this.config.nucleusApiBaseUrl}/api/nucleus/v2/auth/oidc/GoogleOidcClient`;
    // document.location.href = `https://auth-dev.geneious.com/`;
  }
}
