import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NucleusPipelineID } from '../../core/pipeline/pipeline-constants';
import { GENEIOUS_HELP_URL, HELP_CENTER_URL } from '../../app.constants';

@Injectable({
  providedIn: 'root',
})
export class ComponentDisplaySwitches {
  private static instance: ComponentDisplaySwitches;

  private constructor(
    private folderFileView: Observable<boolean>,
    private pipelineAssociations: Observable<NucleusPipelineID[]>,
    private geneiousView: Observable<boolean>,
    private biologicsComponentsView: Observable<boolean>,
  ) {}

  public static getInstance(
    featureBool: Observable<boolean>,
    pipeID: Observable<NucleusPipelineID[]>,
    geneiousView: Observable<boolean>,
    biologicsComponentsView: Observable<boolean>,
  ) {
    if (!ComponentDisplaySwitches.instance) {
      ComponentDisplaySwitches.instance = new ComponentDisplaySwitches(
        featureBool,
        pipeID,
        geneiousView,
        biologicsComponentsView,
      );
    }
    return ComponentDisplaySwitches.instance;
  }

  // if true, folders & files shown
  public showFoldersFiles(): Observable<boolean> {
    return this.folderFileView;
  }
  // if true, links and text refer to Geneious (intended for Geneious Admins)
  public isGeneiousView(): Observable<boolean> {
    return this.geneiousView;
  }

  public showExtraComponents(): Observable<boolean> {
    return this.biologicsComponentsView;
  }

  public getHelpUrl(): Observable<string> {
    return this.geneiousView.pipe(
      map((isGeneiousView) => (isGeneiousView ? GENEIOUS_HELP_URL : HELP_CENTER_URL)),
    );
  }

  public getHelpName(): Observable<string> {
    return this.geneiousView.pipe(
      map((isGeneiousView) =>
        isGeneiousView ? 'Geneious Help Center' : 'Geneious Biologics Help Center',
      ),
    );
  }

  // if true, this org has pipeline associations  (not including 'document-import')
  public hasPipelineAssociations(): Observable<boolean> {
    return this.pipelineAssociations.pipe(
      map((value) => value.filter((value1) => value1 !== 'document-import')),
      map((result) => result.length > 0),
    );
  }
}
