import { Component, OnInit } from '@angular/core';
import { ComponentDisplayService } from '../../../features/feature-switch/component-display.service';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-external-redirect',
  templateUrl: './external-redirect.component.html',
  standalone: true,
  imports: [AsyncPipe],
})
export class ExternalRedirectComponent implements OnInit {
  url: Observable<string>;
  text: Observable<string>;

  constructor(private componentDisplayService: ComponentDisplayService) {}

  ngOnInit() {
    this.text = this.componentDisplayService.get().getHelpName();
    this.url = this.componentDisplayService.get().getHelpUrl();

    console.log('Redirecting to ', this.url);
    this.url.subscribe((url) => {
      window.location.href = url;
    });
  }
}
