import { Component, HostBinding } from '@angular/core';
import { SearchService } from '../search.service';
import { Router } from '@angular/router';
import { CleanUp } from '../../../shared/cleanup';
import { GlobalSearchParams, SearchBarComponent } from '../search-bar/search-bar.component';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { ComponentDisplayService } from '../../../features/feature-switch/component-display.service';

@Component({
  selector: 'bx-search',
  templateUrl: './search.component.html',
  standalone: true,
  imports: [SearchBarComponent, AsyncPipe],
})
export class SearchComponent extends CleanUp {
  @HostBinding('class') readonly hostClass = 'd-block';
  showFoldersFiles$: Observable<boolean>;

  constructor(
    private searchService: SearchService,
    private router: Router,
    private componentDisplayService: ComponentDisplayService,
  ) {
    super();
    this.showFoldersFiles$ = componentDisplayService.get().showFoldersFiles();
  }

  search(query: GlobalSearchParams) {
    const params = {
      queryString: query.term,
      queryType: query.type,
      pagination: { offset: 0, limit: 1000 },
      sort: '',
    };

    this.searchService.updateQuery(params, this.router.url);
    if (this.router.url !== '/search-results') {
      this.router.navigate(['/search-results']);
    }
  }
}
