<bx-settings-breadcrumb
  [crumbs]="[{ link: ['', 'settings'], label: 'Settings' }]"
></bx-settings-breadcrumb>

@if (user(); as user) {
  <div class="bx-page-container bx-page-container-sm">
    <section class="mb-5">
      <h3 class="mb-3">Profile</h3>
      <form #usersForm="ngForm" class="centered" (ngSubmit)="submit(usersForm)">
        @if (errors.length === 0 && areChangesSaved) {
          <div class="alert alert-success" role="alert">Your changes have been saved.</div>
        }
        <div class="mb-3">
          <label class="form-label" for="email">Email Address</label>
          <input
            id="email"
            type="email"
            name="email"
            class="form-control"
            placeholder="Email"
            [(ngModel)]="user.email"
            [disabled]="true"
            ngFormControlValidator
            [validateOnBlur]="true"
            autocomplete="off"
            required
            email
          />
        </div>
        <div class="mb-3">
          <label class="form-label" for="givenName">Given name</label>
          <input
            id="givenName"
            type="text"
            name="givenName"
            class="form-control"
            placeholder="Given Name"
            #givenNameInput="ngModel"
            [(ngModel)]="user.givenName"
            [disabled]="processingRequest"
            ngFormControlValidator
            [validateOnBlur]="true"
            required
          />
          @if (givenNameInput.hasError('required')) {
            <div class="invalid-feedback">Given name is required</div>
          }
        </div>
        <div class="mb-3">
          <label class="form-label" for="familyName">Family name</label>
          <input
            id="familyName"
            type="text"
            name="familyName"
            class="form-control"
            placeholder="Family Name"
            [(ngModel)]="user.familyName"
            [disabled]="processingRequest"
          />
        </div>
        @for (error of errors; track error) {
          <span class="text-danger"> {{ error }} <br /> </span>
        }
        <div class="d-flex justify-content-end">
          <button
            class="btn btn-primary"
            bx-spinner-button
            [disabled]="processingRequest || usersForm.invalid"
            [spin]="processingRequest"
          >
            Save Changes
          </button>
        </div>
      </form>
      <div class="mb-3 row align-items-center">
        <span class="col-2 col-md-1">Roles:</span>
        <div class="col d-flex flex-nowrap">
          @for (role of user.toServerModel().roles; track role) {
            <span class="badge bg-secondary py-2 px-3 rounded-pill me-2">
              {{ formatRole(role) }}
            </span>
          }
        </div>
      </div>
      <div class="mb-3 row align-items-center">
        <span class="col-2 col-md-1">Plan:</span>
        <div class="col">
          <a
            *bxShowIf="isFreeOrg"
            href="https://www.geneious.com/biopharma/plans"
            target="_blank"
            rel="noopener noreferrer"
            class="me-2 fw-bold"
            >Starter</a
          >
          <span *bxShowIf="isAssociateOrg">
            <b>Associate Organization</b>
            <a
              href="https://www.geneious.com/biopharma/plans"
              target="_blank"
              rel="noopener noreferrer"
              class="me-2"
              >(View plans)</a
            >
          </span>
          <b *bxShowIf="isPremiumOrg" class="me-2 fw-bold">Premium</b>
        </div>
      </div>
      <div class="mb-3">
        <a routerLink="/settings/change-password">Change password</a>
      </div>
      @if (isEmailChangingEnabled() && isBXOrg()) {
        <div class="mb-3">
          <a routerLink="/settings/change-email">Change email</a>
        </div>
      }
    </section>
    @if (groups().length > 0) {
      <section class="mb-5">
        <h3 class="list-title mb-3">Groups</h3>
        <div class="list-group">
          @for (group of groups(); track group.id) {
            <div class="list-group-item">
              <p class="mb-0 list-group-item-title">{{ group.name }}</p>
              <p class="mb-0 text-muted">{{ group.description }}</p>
            </div>
          }
        </div>
      </section>
    }
    @if (isApiKeysEnabled()) {
      <section class="mb-5">
        <h3>API Keys</h3>
        <bx-api-keys-table
          [showRefreshButton]="false"
          [showRevokeButton]="true"
          [showCreateButton]="true"
          [apiKeysSource]="'currentUser'"
          [noApiKeysMessage]="
            'You currently have no API keys. Click <a href=\'/settings/create-api-key\'>here</a> to create a new key.'
          "
          class="h-static-2 overflow-y-auto"
        ></bx-api-keys-table>
      </section>
    }
    @if (showMFA$ | async) {
      <section class="mb-5">
        <h3>MFA Keys</h3>
        <bx-mfa-keys-table
          [user]="user"
          [showRevokeButton]="true"
          [showCreateButton]="isMfaEnabled()"
          [noMfaKeysMessage]="
            'You currently have no MFA keys. Click create button to create a new key.'
          "
          class="h-static-2 overflow-y-auto"
        ></bx-mfa-keys-table>
      </section>
    }
  </div>
}
