import { filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ColDef } from '@ag-grid-community/core';
import { UsersService } from './users.service';
import { Router, RouterLink } from '@angular/router';
import User from '../user-editor/user.model';
import { UtilService } from '../../shared/util.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { UsersDeactivationDialogComponent } from './users-deactivation-dialog/users-deactivation-dialog.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import {
  ClientGridComponent,
  ClientGridSelection,
} from '../../features/grid/client-grid/client-grid.component';
import { UserDetailsStatus } from '@geneious/nucleus-api-client';
import { UserStatusRendererComponent } from './user-status-renderer/user-status-renderer.component';
import { AppState } from '../core.store';
import { select, Store } from '@ngrx/store';
import { selectOrganizationID } from '../auth/auth.selectors';
import { formatDateForTables } from '../../shared/string-util';
import { OrganizationService } from '../organisation/organization.service';
import { OrganizationUserInfo } from '@geneious/nucleus-api-client/model/organization-user-info';
import { SettingsBreadcrumbComponent } from '../../shared/breadcrumb/settings-breadcrumb.component';
import { ToolstripComponent } from '../../shared/toolstrip/toolstrip.component';
import { ToolstripItemComponent } from '../../shared/toolstrip/toolstrip-item/toolstrip-item.component';
import { AsyncPipe } from '@angular/common';
import { ComponentDisplayService } from '../../features/feature-switch/component-display.service';

@Component({
  selector: 'bx-users',
  templateUrl: './users.component.html',
  standalone: true,
  imports: [
    SettingsBreadcrumbComponent,
    ToolstripComponent,
    ToolstripItemComponent,
    RouterLink,
    ClientGridComponent,
    AsyncPipe,
  ],
})
export class UsersComponent implements OnInit, OnDestroy {
  @HostBinding('class') readonly hostClass = 'w-100 h-100 d-flex flex-column';
  @ViewChild(ClientGridComponent, { static: true }) clientGridComponent: ClientGridComponent;

  orgID: string;

  // grid related fields
  columnDefs$: Observable<ColDef[]>;
  usersTableData$: Observable<any[]>;
  userInfo: Observable<OrganizationUserInfo> = new BehaviorSubject<OrganizationUserInfo>({
    userLimit: undefined,
    activeUsers: 0,
  });
  selected: any[];
  singleUserIsSelected$: Observable<boolean>;
  selection$ = new BehaviorSubject<ClientGridSelection>({ rows: [], subTableRows: [] });
  deactivateButtonDisabled$: Observable<boolean>;
  deactivateButtonClickedEvent$ = new Subject<void>();
  editButtonClickedEvent$ = new Subject<void>();
  newButtonClickedEvent$ = new Subject<void>();
  newButtonDisabled$: Observable<boolean>;
  hasPipelineAssociations$: Observable<boolean>;
  isGeneiousView$: Observable<boolean>;

  private singleSelectedUser$: Observable<any>;
  private usersDeletionDialogRef: NgbModalRef;

  constructor(
    private utilService: UtilService,
    private usersService: UsersService,
    private organizationService: OrganizationService,
    private router: Router,
    private store: Store<AppState>,
    private dialogService: DialogService,
    private componentDisplayService: ComponentDisplayService,
  ) {
    this.hasPipelineAssociations$ = componentDisplayService.get().hasPipelineAssociations();
    this.isGeneiousView$ = componentDisplayService.get().isGeneiousView();
    this.selected = [];

    this.columnDefs$ = combineLatest([this.hasPipelineAssociations$, this.isGeneiousView$]).pipe(
      map(([PA, GV]: [boolean, boolean]): ColDef[] => {
        if (GV && !PA) {
          //on geneious view, if there are no pipeline associations then the jobs columns should not display
          return [
            {
              field: 'id',
              headerName: '',
              width: 34,
              resizable: false,
              suppressMovable: true,
              checkboxSelection: true,
              headerCheckboxSelection: true,
              valueFormatter: () => '',
            },
            {
              headerName: 'Name',
              field: 'fullName',
              sort: 'asc',
              ...this.utilService.linkRenderer((cell) => ({
                text: cell.value,
                href: `/users/${cell.data.id}`,
              })),
            },
            {
              headerName: 'Email',
              field: 'email',
            },
            {
              headerName: 'Account status',
              field: 'status',
              cellRenderer: UserStatusRendererComponent,
              width: 130,
            },
            {
              headerName: 'Role',
              field: 'role',
              width: 100,
            },
            {
              headerName: 'Last Login Date',
              field: 'lastLoginAt',
              valueFormatter: (params) => formatDateForTables(params.data.lastLoginAt),
            },
          ];
        } else {
          return [
            {
              field: 'id',
              headerName: '',
              width: 34,
              resizable: false,
              suppressMovable: true,
              checkboxSelection: true,
              headerCheckboxSelection: true,
              valueFormatter: () => '',
            },
            {
              headerName: 'Name',
              field: 'fullName',
              sort: 'asc',
              ...this.utilService.linkRenderer((cell) => ({
                text: cell.value,
                href: `/users/${cell.data.id}`,
              })),
            },
            {
              headerName: 'Email',
              field: 'email',
            },
            {
              headerName: 'Account status',
              field: 'status',
              cellRenderer: UserStatusRendererComponent,
              width: 130,
            },
            {
              headerName: 'Role',
              field: 'role',
              width: 100,
            },
            {
              headerName: 'Last Login Date',
              field: 'lastLoginAt',
              valueFormatter: (params) => formatDateForTables(params.data.lastLoginAt),
            },
            { headerName: 'Jobs in Past 30 days', field: 'successfulJobsLast30Days' },
            {
              headerName: 'Last Job Date',
              field: 'lastSuccessfulJobSubmissionTime',
              valueFormatter: (params) =>
                formatDateForTables(params.data.lastSuccessfulJobSubmissionTime),
            },
            {
              headerName: 'Total Jobs',
              field: 'successfulJobsInTotal',
            },
          ];
        }
      }),
    );
  }

  ngOnInit() {
    this.singleSelectedUser$ = this.selection$.pipe(
      filter((selection) => selection.rows.length === 1),
      map((selection) => selection.rows[0]),
    );

    this.singleUserIsSelected$ = this.selection$.pipe(
      map((selection) => selection.rows.length === 1),
    );

    this.deactivateButtonDisabled$ = this.selection$.pipe(
      map(
        (selection) =>
          selection.rows.length !== 1 || selection.rows[0].status === UserDetailsStatus.Deactivated,
      ),
    );

    this.store
      .pipe(select(selectOrganizationID), first())
      .subscribe((organizationID) => (this.orgID = organizationID));
    this.usersTableData$ = this.store.pipe(
      select(selectOrganizationID),
      first(),
      map((organizationID) => organizationID),
      switchMap((orgID) => this.getUserRows(orgID)),
    );
    this.userInfo = this.store.pipe(
      select(selectOrganizationID),
      first(),
      map((organizationID) => organizationID),
      switchMap((orgID) => this.getOrgUserInfo(orgID)),
    );
    this.newButtonDisabled$ = this.userInfo.pipe(
      map((info) => typeof info.userLimit !== 'undefined' && info.activeUsers >= info.userLimit),
    );

    this.deactivateButtonClickedEvent$
      .pipe(
        withLatestFrom(this.singleSelectedUser$),
        map(([_, selectedUser]) => selectedUser),
      )
      .subscribe((selectedUserRow) => this.deactivateFirstSelectedUser(selectedUserRow));

    this.editButtonClickedEvent$
      .pipe(
        withLatestFrom(this.singleSelectedUser$),
        map(([_, selectedUser]) => selectedUser),
      )
      .subscribe((selectedUserRow) => this.editFirstSelectedUser(selectedUserRow));

    this.newButtonClickedEvent$.subscribe(() => this.router.navigate(['/users/new']));
  }

  ngOnDestroy(): void {
    this.deactivateButtonClickedEvent$.complete();
    this.editButtonClickedEvent$.complete();
    this.newButtonClickedEvent$.complete();
    if (this.usersDeletionDialogRef) {
      this.usersDeletionDialogRef.dismiss();
    }
  }

  private deactivateFirstSelectedUser(selectedUserRow: any): void {
    const userID = selectedUserRow.id;
    const userEmail = selectedUserRow.email;

    this.usersDeletionDialogRef = this.dialogService.showDialog({
      component: UsersDeactivationDialogComponent,
      injectableData: {
        userID: userID,
        userEmail: userEmail,
      },
    });
    this.usersDeletionDialogRef.result.then((result) => {
      if (result) {
        this.getUserRows(this.orgID).subscribe((users) => {
          this.clientGridComponent.setRowData(users);
        });
      }
    });
  }

  private editFirstSelectedUser(selectedUserRow: any): void {
    const userID = selectedUserRow.id;
    this.usersService.getUser(userID).subscribe({
      next: (user: User) => {
        this.editUser(user);
      },
      error: () => {
        console.error('Failed to fetch user details for ', userID);
      },
    });
  }

  private editUser(user: User) {
    this.usersService.updateUser(user);
    this.router.navigate(['/users/', user.id]);
  }

  private getUserRows(orgID: string): Observable<any[]> {
    return this.usersService.getUsersWithJobStatistics(orgID);
  }

  private getOrgUserInfo(orgID: string): Observable<OrganizationUserInfo> {
    return this.organizationService.getUserInfo(orgID);
  }
}
