import { Injectable } from '@angular/core';
import { GETTING_STARTED_URL, WORKSPACE_URL } from '../router/app.routing.model';
import { NavigationEnd, Router } from '@angular/router';
import { AppState } from '../core.store';
import { Store } from '@ngrx/store';
import { CleanUp } from '../../shared/cleanup';

// Stores the last used URL so the user can be redirected back to where they were when
// they log back in after being automatically logged out from an inactive session.
@Injectable({
  providedIn: 'root',
})
export class RedirectedURL extends CleanUp {
  private REDIRECT_URL_KEY = 'REDIRECT_URL';

  // navigation to things containing these routes will not clear the last-remembered URL. But they won't be remembered.
  private readonly routesToIgnore: string[] = [
    'sign-up',
    'not-found',
    'account-setup',
    'login',
    'reset-password',
    'confirm-email',
  ];

  // navigation to things containing these routes will clear the last-remembered URL.
  private readonly routesToClearOn: string[] = [
    `/${WORKSPACE_URL}`, // This one I think is used for some weird auth stuff? We have a test that expects it to not be remembered...
    `/${GETTING_STARTED_URL}`,
    'sequence-viewer-image-export',
    '/report',
    'help',
  ];

  constructor(
    private router: Router,
    private store: Store<AppState>,
  ) {
    super();

    // Each time the URL successfully changes, save it in local storage so we remember where we were next time we come back.
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = (event as NavigationEnd).url;
        this.evaluateAndUpdateRedirectUrl(url);
      }
    });
  }

  public getRedirectURL(): string | null {
    const redirectUrl = localStorage.getItem(this.REDIRECT_URL_KEY);
    if (redirectUrl == null || redirectUrl === 'null') {
      return null;
    }
    return redirectUrl;
  }

  /**
   * Sets the redirect URL, no questions asked.
   * @param url
   */
  public setRedirectURL(url: string) {
    localStorage.setItem(this.REDIRECT_URL_KEY, url);
  }

  /**
   * Evaluates the provided URL and updates the last remembered 'REDIRECT_URL' if needed.
   *
   * - If the URL is valid, then sets local storage to it as a 'remember the last location'
   * - If the URL contains parts of {@link RedirectedURL#routesToClearOn|Routes to Clear On}, then local storage will be
   *   cleared. This usually indicates a route that we are unable to navigate back to.
   * - If the URL contains parts of {@link RedirectedURL#routesToIgnore|Routes to Ignore}, then local storage will be
   *   left as is. This is usually a route for an authentication page.
   *
   * @param url
   */
  public evaluateAndUpdateRedirectUrl(url: string) {
    if (
      url == null ||
      url === '/' ||
      url === 'null' ||
      url === 'undefined' ||
      this.routesToClearOn.some((route) => url.includes(route))
    ) {
      localStorage.removeItem(this.REDIRECT_URL_KEY);
    } else if (this.routesToIgnore.some((route) => url.includes(route))) {
      return;
    } else {
      this.setRedirectURL(url);
    }
  }

  public clearRedirectURL() {
    localStorage.removeItem(this.REDIRECT_URL_KEY);
  }
}
