<div class="bx-page-container bx-page-container-lg mt-3">
  <div class="row">
    <div class="col-1 text-end pe-5">
      <bx-logo class="align-self-end" [logo]="'geneious-small-orange.svg'" [size]="2.5"></bx-logo>
    </div>
    <div class="col-10 pt-4">
      @if (geneiousView$ | async) {
        <div class="row mb-2">
          <h3 class="col">Geneious</h3>
        </div>
        <h5 class="text-muted mb-2">
          Here is some text about Geneious and relevant getting started info. Protected routes will
          redirect here. Placeholder text placeholder text.
        </h5>
        <div class="row mb-4">
          <div class="col">
            <a class="btn btn-primary my-3 me-3" [href]="GENEIOUS_HELP_URL" target="_blank">
              Help Article
            </a>
            <a class="btn btn-primary my-3" [href]="GENEIOUS_HELP_URL" target="_blank">
              Geneious Admin Tutorial
            </a>
          </div>
        </div>
      } @else {
        <div class="row mb-4">
          <div class="col">
            <h3 class="mb-4">Analyze Your Antibody Sequences</h3>
            <bx-quick-analysis></bx-quick-analysis>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <hr />
          </div>
        </div>
        <div class="row mb-2">
          <h3 class="col">Get Started with Geneious Biologics</h3>
        </div>
        <div class="row mb-4">
          <div class="col">
            <a class="btn btn-primary" [href]="helpArticleLink$ | async" target="_blank">
              View Tutorials
            </a>
          </div>
        </div>

        <div
          class="bg-light rounded p-4 row no-gutters flex-wrap flex-lg-nowrap align-items-center"
        >
          <div class="col me-3 pb-3 pb-lg-0">
            <h5>Not sure where to start?</h5>
            <p class="text-muted mb-0">
              Try out Geneious Biologics with our sample data and see all the powerful features on
              offer.
            </p>
          </div>
          <div
            id="job-state-container"
            class="col-auto d-flex align-items-center justify-content-center ms-auto px-2"
          >
            @switch (jobState$ | async) {
              @case ('JobRunning') {
                <bx-spinner ngbTooltip="Loading sample data"></bx-spinner>
              }
              @case ('JobCompleted') {
                <a [routerLink]="sampleDataFolderLink$ | async">
                  <mat-icon ngbTooltip="View sample data">done</mat-icon>
                </a>
              }
              @case ('JobFailed') {
                <mat-icon ngbTooltip="Failed to load sample data">error</mat-icon>
              }
            }
            <button
              id="load-sample-data-btn"
              [disabled]="(jobState$ | async) === 'JobRunning'"
              (click)="loadSampleDataBtnClick$.next()"
              class="btn btn-primary ms-2"
            >
              Load Sample Data
            </button>
          </div>
        </div>
      }
    </div>
    <div class="col-1"></div>
  </div>
</div>
