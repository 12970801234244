<bx-cursor-message-container></bx-cursor-message-container>
<bx-logo-nav-bar [ngClass]="{ 'search-enabled': searchOpened$ | async }">
  <button
    class="btn btn-danger readonly-mode"
    *bxShowIf="'disableAllPipelines'"
    disabled="disabled"
  >
    Readonly mode
  </button>
  <div class="flex-grow-1"></div>
  <bx-search class="flex-grow-1"></bx-search>
  <div class="flex-grow-1"></div>
  <a
    href="https://www.geneious.com/biopharma/plans"
    target="_blank"
    rel="noopener noreferrer"
    class="badge bg-white text-brand"
    *bxShowIf="isUnpaidOrg"
    >{{ ['Starter', 'Associate'] | bxTextIfElse: isFreeOrg | async }}</a
  >
  <button
    class="btn account-button ms-2 p-0 text-white"
    [ngbPopover]="userAccountPopoverContent"
    popoverClass="min-w-static-3 bx-popover-fs-base shadow-lg"
    placement="bottom-right"
    autoClose="outside"
    #userAccountPopover="ngbPopover"
  >
    <mat-icon class="align-middle">account_circle</mat-icon>
    <ng-template #userAccountPopoverContent>
      <bx-user-account-dialog
        (clickUserAccount)="userAccountPopover.close()"
      ></bx-user-account-dialog>
    </ng-template>
  </button>
</bx-logo-nav-bar>

<as-split direction="horizontal" unit="percent" (dragEnd)="onSidebarResize($event)">
  <as-split-area
    [size]="initialSidebarWidthPercent"
    [minSize]="8"
    [maxSize]="50"
    id="bx-sidebar"
    data-testid="bx-sidebar"
    class="d-flex flex-column position-relative overflow-hidden"
  >
    <div class="folders-wrapper flex-grow-1 flex-shrink-1 overflow-auto py-1">
      <bx-folder-tree [folder]="gettingStartedPage"></bx-folder-tree>
      <hr class="my-1" />
      @if (showFoldersFiles$ | async) {
        @if (sharedRoot$ | async; as sharedRoot) {
          <bx-folder-tree [folder]="sharedRoot" [openByDefault]="true"> </bx-folder-tree>
        }
        <hr class="my-1" />
        @if (showExtraComponents$ | async) {
          @if (referenceDatabaseRoot$ | async; as referenceDatabaseRoot) {
            <bx-folder-tree [folder]="referenceDatabaseRoot" class="py-1"> </bx-folder-tree>
          }
        }
        <hr class="my-1" />
      }
      <ng-container *bxHideIf="isFreeOrg">
        @if (isAdmin$ | async) {
          <div>
            <div class="text-truncate px-2">
              <small id="sidebar-admin" class="text-muted">Administration</small>
            </div>
            <bx-folder-tree [folder]="usersRoot"></bx-folder-tree>
            <bx-folder-tree [folder]="userGroupsRoot"></bx-folder-tree>
            @if (showFoldersFiles$ | async) {
              @if (isNucleusAdmin$ | async) {
                <bx-folder-tree [folder]="geneiousAdmin"></bx-folder-tree>
              }
            }
            <hr class="my-1" />
          </div>
        }
      </ng-container>
      @if (showExtraComponents$ | async) {
        <ng-container *bxHideIf="isFreeOrg">
          <div class="text-truncate px-2">
            <small id="sidebar-organization-settings" class="text-muted">
              Organization Settings
            </small>
          </div>
          <bx-folder-tree
            [folder]="nameSchemesRoot"
            *bxShowIf="'nameSchemes'"
            class="mb-1"
          ></bx-folder-tree>
          <bx-folder-tree [folder]="labelsRoot" class="mb-1"></bx-folder-tree>
          @if (bioregisterConfigEnabled$ | async) {
            <bx-folder-tree [folder]="bioRegister" class="mb-1"></bx-folder-tree>
          }
          @if (lumaConfigEnabled$ | async) {
            <bx-folder-tree [folder]="luma" class="mb-1"></bx-folder-tree>
          }
          <hr class="my-1" />
        </ng-container>
      }
      <a
        bx-folder-tree-link
        [href]="helpUrl | async"
        target="_blank"
        linkIcon="live-help"
        linkText="Help / Support"
        class="d-block py-2"
      ></a>
      <div class="py-2"></div>
    </div>

    @if (pipelineAssociations$ | async) {
      <div class="bg-light border-top flex-shrink-0 justify-content-end py-2">
        <bx-job-tree class="mb-1"></bx-job-tree>
        <bx-activity-tree></bx-activity-tree>
      </div>
    }
  </as-split-area>

  <as-split-area [size]="initialMainContentWidthPercent">
    <div class="main">
      <router-outlet class="secure"></router-outlet>
    </div>
  </as-split-area>
</as-split>
